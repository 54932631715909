import axios,{ AxiosRequestConfig,AxiosResponse } from "axios"; // 引用axios
import { ElLoading } from 'element-plus'
// import config from "./config";

let filterLoadUrlList = [
  "/getUploadGoodsStatus"
]

let loading:any;
const startLoading = ()=>{
  interface Options{
    lock:boolean,
    text:string,
    background:string
  }
  const options:Options = {
    lock:true,
    text:'加载中',
    background:'rgba(0,0,0,0.7)'
  }
  loading = ElLoading.service(options)
}
const endLoading = ()=>{
  loading.close()
}
// 请求拦截
axios.interceptors.request.use((config:AxiosRequestConfig)=>{
  let existIndex = filterLoadUrlList.findIndex((item)=> config?.url?.includes(item))
  if(existIndex == -1){
    // 加载  
    startLoading()
  }
  let token = localStorage.getItem('seckillToken')
  if(token){
    config.headers!.Authorization = `Bearer ${token}`

    //查看过期时间
    let expires = localStorage.getItem('expires')
    let nowTime = (new Date()).getTime()
    if (!expires) {
      let expiresTime = nowTime + (1000 * 60 * 60 * 8)
      localStorage.setItem('expires', expiresTime + "")
    } else {
      var expiresTime:number = Number(expires)
      if (nowTime > expiresTime) {
        window.localStorage.removeItem("seckillToken");
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("expires");
        config.headers!.token = ''
      }
    }
  }
  config.headers!.platform = 'sop';
  //金丝雀
  // const sopData = localStorage.getItem('seckillUser') as any
  // const userData =JSON.parse(sopData);
  // if(userData && userData.id && userData.isCanary){
  //   config.headers!.canary = 1
  // //   if(userData.isCanary==2){
  // //     config.headers!.url = `http://api.test.66lego.cn${path}`;
  // //   }
  //  }
  let fingerprint = (window as any).fingerprint || '';
  config.headers!.fingerprint = fingerprint;
  return config;
})

// 响应拦截
axios.interceptors.response.use((response:AxiosResponse<any>)=>{
  // 结束加载
  endLoading()
  return response
},error=>{
  endLoading()
  // 错误提醒
  return Promise.reject(error)
})

export default axios;