// import { baseUrl } from "./config"
let baseUrl = process.env.VUE_APP_URL
// const sopData = localStorage.getItem('seckillUser') as any
// const userData =JSON.parse(sopData);
// if(userData && userData.id && userData.isCanary){
//     if(userData.isCanary==2){
//         baseUrl = `http://api.test.66lego.cn`;
//     }
// }
export default {

    /**
     * 主页
     */
    statHomeDay: `${baseUrl}/api/v1/stat/agent/day/statHomeDay`,//今日昨日统计 
    statHomeSale: `${baseUrl}/api/v1/stat/agent/day/statHomeSale`,//销量统计
    statHome: `${baseUrl}/api/v1/stat/agent/day/statHome`,//首页信息 
    
    homeGoodsRanking: `${baseUrl}/api/v1/mall/goods/selectPage`,//商品销量排行 
    
    homeMemberCount: `${baseUrl}/api/v1/stat/agent/day/getMemberCount`,//会员统计
    homeDailyInfo: `${baseUrl}/api/v1/stat/agent/day/getDailyInfo`,//首页信息 

    homeIndexInfo: `${baseUrl}/api/v1/stat/agent/day/getIndexInfo`,//首页近7天统计 

    /**
     * sop用户
     */
    SOP_USER_SESSION: `${baseUrl}/api/v1/os/users/session`,//登录
    SOP_USER: `${baseUrl}/api/v1/os/users`,//登录

    /**
     * 用户
     */
    MEMBER_ABOUT: `${baseUrl}/api/v1/members`,//用户列表
    MEMBER_REGISTER: `${baseUrl}/api/v1/members/registerMember`,//注销会员
    memberSetList: `${baseUrl}/api/v1/member/level/sets/selectPage`,//用户设置列表 

    /**
     * 订单
     */
    ORDERS: `${baseUrl}/api/v1/mall/orders`,//订单列表
    ORDERS_RETURN_BILL: `${baseUrl}/api/v1/mall/after/sale/orders`,//订单 
    ORDERS_REMARK:`${baseUrl}/api/v1/mall/orders/sendRemark`,//订单备注 
    ORDERS_SYNC_PAY:`${baseUrl}/api/v1/mall/orders/syncPayStatus`,//同步支付
    GENERATE_INVOICE:`${baseUrl}/api/v1/wm/invoices/generate/invoice`, //生成发货单
    ORDERS_EXPORT:`${baseUrl}/api/v1/mall/orders/doExcelOut`,//订单导出  
    ORDERS_EXAM_EXPORT:`${baseUrl}/api/v1/exam/reserves/exportExam`,//体检订单导出  
    ORDERS_EXAM_IMPORT:`${baseUrl}/api/v1/exam/reserves/importExam`,//体检订单导入 
    ORDERS_AMOUNT_EXPORT:`${baseUrl}/api/v1/mall/orders/exportSupplierAmount`,//对账订单导出 
    ORDERS_VIP_LIST:`${baseUrl}/api/v1/member/pay/records`,//权益订单
    ORDERS_VIP_EXPORT:`${baseUrl}/api/v1/member/pay/records/exportOrder`,//权益订单导出
    ORDERS_SALE:`${baseUrl}/api/v1/mall/after/sale/orders/systemAfterSaleOrder`,//订单售后申请

    /**
     * 权限
     */
    RBAC_ROLE: `${baseUrl}/api/v1/rbac/roles`,//角色管理
    RBAC_PERM: `${baseUrl}/api/v1/rbac/perms`,//权限管理

    /**
     * 商品
     */
    getClassifyList: `${baseUrl}/api/v1/mall/goods/types/selectAll`,//获取商品分类列表
    getCategory: `${baseUrl}/api/v1/mall/goods/types/selectAll`,//一级品类
    getGoodsList: `${baseUrl}/api/v1/mall/goods/selectPage`,//获取商品列表
    goodsTagList: `${baseUrl}/api/v1/mall/tags/selectList`,//商品标签 
    cascadeClassify: `${baseUrl}/api/v1/mall/goods/cats/cascade`,//分类级联 
    getGoodsDetail: `${baseUrl}/api/v1/mall/goods/selectById`,//获取商品详情
    insertGoods: `${baseUrl}/api/v1/mall/goods/insert`,//添加商品 
    editGoods: `${baseUrl}/api/v1/mall/goods/updateById`,//修改商品
    copyGoods: `${baseUrl}/api/v1/mall/goods/copyGoods`,//复制商品
    changeGoodsStatus:`${baseUrl}/api/v1/mall/goods/updateById`,//切换商品上下架状态
    deleteGoods:`${baseUrl}/api/v1/mall/goods/`,//删除商品
    LIST_GOODS_CAT: `${baseUrl}/api/v1/mall/goods/cats`, //商品分类 
    LIST_GOODS_CAT_TREE: `${baseUrl}/api/v1/mall/goods/cats/tree`, //商品分类树形结构
    uploadGoodsExcelStatus:`${baseUrl}/api/v1/mall/goods`,// 上传商品 

    /**
     * 运费模板
     * 运费价格规则
     */
    LIST_FREIGHT_TEMPLATE: `${baseUrl}/api/v1/mall/freight/templates`, //运费模板

    /**
     * 财务管理
     */
    PAY_ABOUT: `${baseUrl}/api/v1/pay/payments`, //支付列表 
    PAY_ORDER_EXPORT: `${baseUrl}/api/v1/pay/payments/exportExcel`, //支付订单导出

    /**
     * 供应商
     */
    getSupplierList: `${baseUrl}/api/v1/mall/goods/suppliers/selectList`,//获取供应商列表

    /**
     * 品牌
     */
    getBrandPage: `${baseUrl}/api/v1/mall/brands/selectPage`,//品牌管理获取列表
    brandInsert: `${baseUrl}/api/v1/mall/brands/insert`,//品牌管理添加
    brandEdit: `${baseUrl}/api/v1/mall/brands/updateById`,//品牌管理编辑
    brandDelete: `${baseUrl}/api/v1/mall/brands/deleteById`,//品牌管理删除 
    brandOpenClose: `${baseUrl}/api/v1/mall/brands/updateById`,//品牌管理状态更改 
    getBrandList: `${baseUrl}/api/v1/mall/brands/selectList`,
    getBrandCatList: `${baseUrl}/api/v1/mall/brand/cats`,
    brandCatInsert: `${baseUrl}/api/v1/mall/brand/cats`,//品牌分类管理添加
    brandCatUpdate: `${baseUrl}/api/v1/mall/brand/cats`,//品牌分类管理添加
    brandCatDelete: `${baseUrl}/api/v1/mall/brand/cats`,//品牌分类管理添加

    /**
     * 视频会员
     */
    getXinProductPage:`${baseUrl}/api/v1/xin/products`,
    getXinProductItem:`${baseUrl}/api/v1/xin/product/items`,
    xinProductDelete:`${baseUrl}/api/v1/xin/products`,
    xinProductInsert:`${baseUrl}/api/v1/xin/products`,
    xinProductUpdate:`${baseUrl}/api/v1/xin/products`,


    /**
     * 运营管理
     */
    advertList: `${baseUrl}/api/v1/mall/banner/ads`,//广告管理列表
    deleteAdvertise: `${baseUrl}/api/v1/mall/banner/ads/deleteById`,//删除广告
    insertAdvertise: `${baseUrl}/api/v1/mall/banner/ads/insert`,//广告管理添加
    modifyAdvertise: `${baseUrl}/api/v1/mall/banner/ads/updateById`,//广告管理编辑

    noticeList: `${baseUrl}/api/v1/system/notices/selectPage`,//公告列表 
    insertNotice: `${baseUrl}/api/v1/system/notices/insert`,//添加公告
    modifyNotice: `${baseUrl}/api/v1/system/notices/updateById`,//修改公告
    deleteNotice: `${baseUrl}/api/v1/system/notices/deleteById`,//删除公告 

    agreementList: `${baseUrl}/api/v1/mall/agreements/selectPage`,//协议管理列表
    agreementModfiy: `${baseUrl}/api/v1/mall/agreements/updateById`,//协议管理修改

    /**
     * 供应商管理
     */
    supplierList: `${baseUrl}/api/v1/mall/goods/suppliers/selectPage`,//供应商管理列表 
    insertSupplier: `${baseUrl}/api/v1/mall/goods/suppliers/insert`,//供应商添加
    editSupplier: `${baseUrl}/api/v1/mall/goods/suppliers/updateById`,//编辑
    deleteSupplier: `${baseUrl}/api/v1/mall/goods/suppliers/deleteById`,//删除 

    ADDRESS_ABOUT: `${baseUrl}/api/v1/mall/supplier/receive/address`,//供应商退货地址 

    /****
     * 物流相关
     * ***/
    LOGISTICS_ABOUT: `${baseUrl}/api/v1/mall/orders/getOrderLogisticsInfo`,
    SHIPPING:`${baseUrl}/api/v1/mall/express/shippings`,//物流列表
    LOGISTICS_INFO:`${baseUrl}/api/v1/lm/logistics`,//物流记录

    /**
     * 发货单
     */
    INVOLVE: `${baseUrl}/api/v1/wm/invoices`,
    SEND_EXPRESS: `${baseUrl}/api/v1/mall/orders/sendExpress`, 
    INVOICE_EXPORT: `${baseUrl}/api/v1/wm/invoices/doExcelOut`,//发货单导出
    INVOICE_IMPORT: `${baseUrl}/api/v1/wm/invoices/doExcelImport`,//发货单导入

    /**
     * 售后
     */
     afterSaleAddress:`${baseUrl}/api/v1/mall/after/sale/orders/selectPage`,//售后地址
     agreenReview:`${baseUrl}/api/v1/mall/after/sale/orders/auditAfterSale`,//售后审核
     agreenRefound:`${baseUrl}/api/v1/mall/after/sale/orders/confirmReceiveAndReturnMoney`,//售后退款
     afterSaleDeliverGoods:`${baseUrl}/api/v1/mall/after/sale/orders/deliverGoodsByBusiness`,//售后发货 

     /**
     * 活动
     */
    couponList:`${baseUrl}/api/v1/mktCoupon/selectPage`,
    couponInsert:`${baseUrl}/api/v1/mktCoupon/insert`,
    couponDetail:`${baseUrl}/api/v1/mktCoupon/selectById`,
    couponAllDetail:`${baseUrl}/api/v1/mktCoupon/detail`,
    couponOrderShared:`${baseUrl}/api/v1/mktCouponOrder/sharedImg`,
    couponUpdate:`${baseUrl}/api/v1/mktCoupon/updateById`,
    couponStatus:`${baseUrl}/api/v1/mktCoupon/status`,
    couponDistribute:`${baseUrl}/api/v1/mktCouponOrder/distribute`,
    couponOrderList:`${baseUrl}/api/v1/mktCouponOrder/selectPage`,
    couponUserLog:`${baseUrl}/api/v1/mktCoupon/selectUserCouponPage`,

    /**
     * 图片上传
     */
    uploadImg: `${baseUrl}/api/v1/aliyun/uploadFile`,//图片上传

    /**
     * 视频管理
     */
     OTHER_VIDEO_LIST: `${baseUrl}/api/v1/other/videos`,//视频列表
     OTHER_VIDEO_UP_DOWN: `${baseUrl}/api/v1/other/videos/upDown`,// 上传/撤回 

    /**
     * 素材管理
     */
     OTHER_SOURCE_LIST: `${baseUrl}/api/v1/other/sources`,//素材列表 
     OTHER_SOURCE_UP_DOWN: `${baseUrl}/api/v1/other/sources/upDown`,// 上传/撤回 
     OTHER_SOURCE_TYPE_TREE: `${baseUrl}/api/v1/other/source/types/tree`, //素材分类树形结构

    /**
     * 阿里云
     */
     ALI_YUN_POLICY: `${baseUrl}/api/v1/aliyun/policy`,//凭证
     ALI_YUN_SIGN: `${baseUrl}/api/v1/aliyun/sign`,//凭证 

     /**
     * 积分
     */
     getIntegralList:`${baseUrl}/api/v1/user/integral/records`,


    /**
     * 修改会员等级
     * **/  
    updateMemberLevel:`${baseUrl}/api/v1/members/updateMemberLevel`,
    /**
     * 获取消息列表 
     * **/ 
    getNewsList:`${baseUrl}/api/v1/bi/notices/getSystemNotice`,
    /**
     *  新增系统消息
     * */ 
    addNews:`${baseUrl}/api/v1/bi/notices/addSystemNotice`,
    /**
     * 操作系统消息
     * */ 
    operationNews:`${baseUrl}/api/v1/bi/notices/optionSystemNotice`,

      /**
     * 消息
     */
    noticeCat:`${baseUrl}/api/v1/bi/notice/cats`,
    noticeCatInsert:`${baseUrl}/api/v1/bi/notice/cats/insert`,
    noticeCatDelete:`${baseUrl}/api/v1/bi/notice/cats/deleteById`,
    noticeContent:`${baseUrl}/api/v1/bi/notice/contents`,
    noticeContentDetail:`${baseUrl}/api/v1/bi/notice/contents`,
    noticeContentStatus:`${baseUrl}/api/v1/bi/notice/contents/status`,
    noticeContentInsert:`${baseUrl}/api/v1/bi/notice/contents/insert`,
    noticeContentUpdate:`${baseUrl}/api/v1/bi/notice/contents/updateById`,

    /**
     * 抢购
     * */ 
    flashSaleList:`${baseUrl}/api/v1/mkt/rush/buys/getList`,//列表
    flashSaleCreate:`${baseUrl}/api/v1/mkt/rush/buys/addRushBuy`,//创建
    flashSaleDetail:`${baseUrl}/api/v1/mkt/rush/buys`,//详情
    flashSaleEdit:`${baseUrl}/api/v1/mkt/rush/buys/updateRushBuy`,//编辑
    flashSaleOn:`${baseUrl}/api/v1/mkt/rush/buys/offOrOn`,//启用或禁用

    /**
     * 标签
     * **/ 
    taglibCats:`${baseUrl}/api/v1/ai/tag/cats`,//标签分类
    taglibTags:`${baseUrl}/api/v1/ai/tags`,//标签
    taglibValues:`${baseUrl}/api/v1/ai/tag/values`,//标签值
    taglibUpload:`${baseUrl}/api/v1/ai/tags`,//标签值

      /**
     * 爬虫
     * **/ 
    crawlJob:`${baseUrl}/api/v1/crawl/jobs`,//爬虫任务
    crawlJobExcel:`${baseUrl}/api/v1/crawl/jobs/uploadExcel`,//爬虫任务
    crawlRule:`${baseUrl}/api/v1/crawl/rules`,//爬虫规则
    crawlJobItem:`${baseUrl}/api/v1/crawl/jobs/item`,//爬虫任务详情
    /**
     * 加价购
     * */ 
    markupList:`${baseUrl}/api/v1/mkt/activitys/getList`,//列表
    markupAdd:`${baseUrl}/api/v1/mkt/up/sale/addUpSale`,//新增
    markupEdit:`${baseUrl}/api/v1/mkt/up/sale/editUpSale`,//编辑
    markupExchange:`${baseUrl}/api/v1/mkt/activitys/getActivityList`,//换购列表
    markupOffOn:`${baseUrl}/api/v1/mkt/activitys/offOrOn`,//活动启用、禁用
    markupDetail:`${baseUrl}/api/v1/mkt/activitys/getDetails`,//详情
    orderExtUpdateByOrderId:`${baseUrl}/api/v1/mall/orders/orderExtUpdateByOrderId`,//修改订单
    orderSettleCancel:`${baseUrl}/api/v1/mall/orders/orderSettleCancel`,//订单结算/取消
    exportSettleOrder:`${baseUrl}/api/v1/mall/orders/exportSettleOrder`,//导出结算订单
    addInsert:`${baseUrl}/api/v1/mall/goods/supplier/invoices/insert`,// 供应商发票新增
    getListBySupplierId:`${baseUrl}/api/v1/mall/goods/supplier/invoices/getListBySupplierId`,// 供应商发票列表
    removeById:`${baseUrl}/api/v1/mall/goods/supplier/invoices/removeById`,//  删除发票 id
};