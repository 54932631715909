import { STATEMENT_OR_BLOCK_KEYS } from '@babel/types'
import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

export interface State {
  pageNumber: number,
  tabPaneName: string,
  screenData: any,

  //用户
  memberDetailId: string,
  memberListDetail: number,
  // 商品
  goodsListDetail: number,
  goodsItemId: string,
  richText: string,
  freightListDetail: number,
  freightItemId: string,

  // 运营管理
  agreementRichText: string,

  // 订单
  orderListDetail: number,
  orderdetailId: string,
  afterSales: number,
  orderActiveName:string,//订单列表
  sellActiveName:string,//售后列表
  afterSaleDetailId: string,

  //营销
  collapse: boolean,
  agentInviteLog: boolean,
  agentListDetail: number,
  withdrawalListReview: number,
  collageShowType: number,
  collageItemId:string,
  memberSetItemId: string,
  collageListDetail:number,
  collageDetailId:string,
  agentDetailId: string,
  couponShowType:number,
  couponItemId:number,

  // 发货单
  invoiceStatus:number,
  logisticsId:string,

  // 其他
  singleImageSort:string,
  newsListDetail:number,
  flashSaleShowType:number,
  flashSaleItemId:string,
  markupPurchaseShowType:number,
  markupPurchaseItemId:string

}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    pageNumber: 1,
    tabPaneName: 'first',
    screenData: {},
    // 用户
    memberDetailId: '',
    memberListDetail: 0,
    // 商品
    goodsListDetail: 0,
    goodsItemId: '',
    richText: '',
    freightListDetail: 0,
    freightItemId: '',

    // 运营管理
    agreementRichText: '',

    // 订单
    orderListDetail: 0,
    orderdetailId: '',
    afterSales: 0,
    orderActiveName:'',//订单列表 
    sellActiveName:'',//售后列表
    afterSaleDetailId: '',

    // 发货单
    invoiceStatus:0,
    logisticsId:'',

    //营销
    collapse: false,
    agentInviteLog:false,
    agentListDetail: 0,
    agentDetailId: '',
    withdrawalListReview: 0,
    collageShowType: 0,
    collageItemId:'',
    memberSetItemId: '',
    collageListDetail:0,
    collageDetailId:'',
    couponShowType:0,
    couponItemId:0,


    // 其他
    singleImageSort:'',
    newsListDetail:0,
    flashSaleShowType:0,
    flashSaleItemId:'',

    markupPurchaseShowType:0,
    markupPurchaseItemId:''

  },
  mutations: {
    setPageNumber: (state: State, pageNumber: number) => {
      state.pageNumber = pageNumber
    },
    setTabPane: (state: State, tabPaneName: string) => {
      state.tabPaneName = tabPaneName
    },
    // screenData
    setScreenData: (state: State, screenData: number) => {
      state.screenData = screenData
    },

    // 用户
    setMemberDetailId: (state: State, memberDetailId: string) => {
      state.memberDetailId = memberDetailId
    },
    setMemberListDetail: (state: State, memberListDetail: number) => {
      state.memberListDetail = memberListDetail
    },
    // 商品
    setGoodsListDetail: (state: State, goodsListDetail: number) => {
      state.goodsListDetail = goodsListDetail
    },
    setGoodsItemId: (state: State, goodsItemId: string) => {
      state.goodsItemId = goodsItemId
    },
    setRichText: (state: State, richText: string) => {
      state.richText = richText
    },
    setFreightListDetail: (state: State, freightListDetail: number) => {
      state.freightListDetail = freightListDetail
    },
    setFreightItemId: (state :State, freightItemId :string) => {
      state.freightItemId = freightItemId
    },

    // 运营管理
    setAgreementRichText: (state: State, agreementRichText: string) => {
      state.agreementRichText = agreementRichText
    },

    // 订单
    setOrderListDetail: (state: State, orderListDetail: number) => {
      state.orderListDetail = orderListDetail
    },
    setOrderdetailId: (state: State, orderdetailId: string) => {
      state.orderdetailId = orderdetailId
    },
    setAfterSales: (state: State, afterSales: number) => {
      state.afterSales = afterSales
    },
    setOrderActiveName:(state:State,orderActiveName:string)=>{
      state.orderActiveName = orderActiveName
    }, 
    setSellActiveName:(state:State,sellActiveName:string)=>{
      state.sellActiveName = sellActiveName
    }, 
    setAfterSaleDetailId: (state: State, afterSaleDetailId: string) => {
      state.afterSaleDetailId = afterSaleDetailId
    },

    // 发货单
    setInvoiceStatus:(state:State,invoiceStatus:number)=>{
      state.invoiceStatus = invoiceStatus
    },
    setLogisticsId:(state:State,logisticsId:string)=>{
      state.logisticsId = logisticsId
    },

    //营销
    setCollapse: (state: State, collapse: boolean) => {
      state.collapse = collapse
    },
    setAgentInivteLogDetail: (state: State, agentInviteLog: boolean) => {
      state.agentInviteLog = agentInviteLog
    },
    setAgentListDetail: (state: State, agentListDetail: number) => {
      state.agentListDetail = agentListDetail
    },
    setCollageShowType: (state: State, collageShowType: number) => {
      state.collageShowType = collageShowType
    },
    setCollageItemId: (state: State, collageItemId: string) => {
      state.collageItemId = collageItemId
    },
    setMemberSetItemId: (state: State, memberSetItemId: string) => {
      state.memberSetItemId = memberSetItemId
    },
    setCollageListDetail: (state: State, collageListDetail: number) => {
      state.collageListDetail = collageListDetail
    },
    setCollageDetailId:(state: State, collageDetailId: string) => {
      state.collageDetailId = collageDetailId
    },
    setWithdrawalListReview: (state: State, withdrawalListReview: number) => {
      state.withdrawalListReview = withdrawalListReview
    },
    setAgentDetailId: (state: State, agentDetailId: string) => {
      state.agentDetailId = agentDetailId
    },
    setCouponShowType:(state: State, couponShowType: number) => {
      state.couponShowType = couponShowType
    },
    setCouponItemId:(state: State, couponItemId: number) => {
      state.couponItemId = couponItemId
    },
    // 其他
    setSingleImageSort:(state:State,singleImageSort:string)=>{
      state.singleImageSort = singleImageSort
    },
    setNewsListDetail:(state:State,newsListDetail:number) => {
      state.newsListDetail = newsListDetail
    },
    setFlashSaleShowType:(state:State,flashSaleShowType:number) => {
      state.flashSaleShowType = flashSaleShowType
    },
    setFlashSaleItemId:(state:State,flashSaleItemId:string) => {
      state.flashSaleItemId = flashSaleItemId
    },
    setMarkupPurchaseShowType:(state :State, markupPurchaseShowType:number) => {
      state.markupPurchaseShowType = markupPurchaseShowType
    },
    setMarkupPurchaseItemId:(state :State, markupPurchaseItemId:string) => {
      state.markupPurchaseItemId = markupPurchaseItemId
    },

  },
  getters: {
    // pageNumber
    getPageNumber: (state: State) => {
      return state.pageNumber
    },
    getTabPane: (state: State) => {
      return state.tabPaneName
    },
    // screenData
    getScreenData: (state: State) => {
      return state.screenData
    },
    // 用户
    getMemberDetailId: (state: State) => {
      return state.memberDetailId
    },
    getMemberListDetail: (state: State) => {
      return state.memberListDetail
    },

    // 商品
    getGoodsListDetail: (state: State) => {
      return state.goodsListDetail
    },
    getGoodsItemId: (state: State) => {
      return state.goodsItemId
    },
    getRichText: (state: State) => {
      return state.richText
    },
    getFreightListDetail: (state :State) => {
      return state.freightListDetail
    },
    getFreightItemId: (state: State) => {
      return state.freightItemId
    },

    // 运营管理
    getAgreementRichText: (state: State) => {
      return state.agreementRichText
    },

    // 订单
    getOrderListDetail: (state: State) => {
      return state.orderListDetail
    },
    getOrderdetailId: (state: State) => {
      return state.orderdetailId
    },
    getAfterSales: (state: State) => {
      return state.afterSales
    },
    getOrderActiveName:(state:State)=>{
      return state.orderActiveName
    },
    getSellActiveName:(state:State)=>{
      return state.sellActiveName
    },
    getAfterSaleDetailId: (state: State) => {
      return state.afterSaleDetailId
    },

    // 发货单
    getInvoiceStatus:(state:State)=>{
      return state.invoiceStatus
    },
    getLogisticsId:(state:State)=>{
      return state.logisticsId
    },

    //营销
    getCollapse: (state: State) => {
      return state.collapse
    },
    getAgentInivteLogDetail: (state: State) => {
      return state.agentInviteLog
    },
    getAgentListDetail: (state: State) => {
      return state.agentListDetail
    },
    getCollageShowType: (state: State) => {
      return state.collageShowType
    },
    getCollageItemId: (state: State) => {
      return state.collageItemId
    },
    getMemberSetItemId: (state: State) => {
      return state.memberSetItemId
    },
    getCollageListDetail: (state: State) => {
      return state.collageListDetail
    },
    getCollageDetailId:(state: State) => {
      return state.collageDetailId
    },
    getWithdrawalListReview: (state: State) => {
      return state.withdrawalListReview
    },
    getCouponShowType:(state: State) => {
      return state.couponShowType
    },
    getCouponItemId:(state: State) => {
      return state.couponItemId
    },
    // 其他
    getSingleImageSort:(state:State)=>{
      return state.singleImageSort
    },
    getNewsListDetail:(state:State) => {
      return state.newsListDetail
    },
    getFlashSaleShowType:(state:State) => {
      return state.flashSaleShowType
    },
    getFlashSaleItemId:(state:State) => {
      return state.flashSaleItemId
    },
    getMarkupPurchaseShowType:(state:State) => {
      return state.markupPurchaseShowType
    },
    getMarkupPurchaseItemId:(state:State) => {
      return state.markupPurchaseItemId
    },
    
  }
})

// 定义自己的 `useStore` 组合式函数
export function useStore() {
  return baseUseStore(key)
}