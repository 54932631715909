import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: "/index",
        component: () => import("@/views/home/home.vue"),
        name: "index",
        meta: {
          title: "首页",
          icon: "el-icon-s-home",
        },
      },
    ]
  }, {
    path: "/jurisdiction",
    component: Layout,
    name: "jurisdiction",
    meta: {
      title: '访问权限',
      icon: 'el-icon-decument',
      roles: ["sys:jurisdiction"],
    },
    children: [
      {
        path: "/permList",
        component: () => import("@/views/jurisdiction/permList.vue"),
        name: "permList",
        meta: {
          title: '权限管理',
          icon: 'el-icon-decument',
          roles: ["sys:permList"],
        },
      }, {
        path: "/userList",
        component: () => import("@/views/jurisdiction/userList.vue"),
        name: "userList",
        meta: {
          title: '用户管理',
          icon: 'el-icon-decument',
          roles: ["sys:userList"],
        },
      }, {
        path: "/roleList",
        component: () => import("@/views/jurisdiction/roleList.vue"),
        name: "roleList",
        meta: {
          title: '角色管理',
          icon: 'el-icon-decument',
          roles: ["sys:roleList"],
        },
      }
    ]
  }, {
    path: "/member",
    component: Layout,
    name: "member",
    meta: {
      title: '用户管理',
      icon: 'el-icon-decument',
      roles: ["sys:USER_MANAGE"],
    },
    children: [
      {
        path: "/memberList", 
        component: () => import("@/views/member/memberIndex.vue"), 
        name: "memberList",
        meta: {
          title: '会员管理',
          icon: 'el-icon-decument',
          roles: ["sys:memberList"],
        },
      },
      {
        path: "/taglib", 
        component: () => import("@/views/member/taglib.vue"), 
        name: "taglib",
        meta: {
          title: '标签分类',
          icon: 'el-icon-decument',
          roles: ["sys:taglib"],
        },
      },
    ]
  }, {
    path: "/goods",
    component: Layout,
    name: "goods",
    meta: {
      title: '商品管理',
      icon: 'el-icon-decument',
      roles: ["sys:GOODS_MANAGE"],
    },
    children: [
      {
        path: "/goodsList", 
        component: () => import("@/views/goods/goodsIndex.vue"), 
        name: "goodsList",
        meta: {
          title: '商品列表',
          icon: 'el-icon-decument',
          roles: ["sys:goodsList"],
        },
      },
      {
        path: "/goodsCat", 
        component: () => import("@/views/goods/goodsCat.vue"), 
        name: "goodsCat",
        meta: {
          title: '商品分类',
          icon: 'el-icon-decument',
          roles: ["sys:goodsCat"], 
        },
      },
      {
        path: "/management", 
        component: () => import("@/views/goods/management.vue"), 
        name: "management",
        meta: {
          title: '品牌管理', 
          icon: 'el-icon-decument',
          roles: ["sys:management"],  
        },
      },
      {
        path: "/product", 
        component: () => import("@/views/goods/product.vue"), 
        name: "product",
        meta: {
          title: '品牌管理', 
          icon: 'el-icon-decument',
          roles: ["sys:product"],  
        },
      },
      {
        path: "/freight", 
        component: () => import("@/views/goods/freight.vue"), 
        name: "freight",
        meta: {
          title: '运费管理', 
          icon: 'el-icon-decument',
          roles: ["sys:freight"],  
        },
      }
    ]
  }, {
    path: "/finance",
    component: Layout,
    name: "finance",
    meta: {
      title: '财务管理',
      icon: 'el-icon-decument',
      roles: ["sys:finance"],
    },
    children: [
      {
        path: "/payList", 
        component: () => import("@/views/finance/paymentList.vue"), 
        name: "payList",
        meta: {
          title: '支付列表',
          icon: 'el-icon-decument',
          roles: ["sys:payList"],
        },
      }
    ]
  }, {
    path: "/operate",
    component: Layout,
    name: "operate",
    meta: {
      title: '运营管理',
      icon: 'el-icon-decument',
      roles: ["sys:operate"],
    },
    children: [
      {
        path: "/videoManage", 
        component: () => import("@/views/operate/videoList.vue"),  
        name: "videoManage",
        meta: {
          title: '视频管理',
          icon: 'el-icon-decument',
          roles: ["sys:videoManage"],
        },
      },
      {
        path: "/sourceManage", 
        component: () => import("@/views/operate/sourceList.vue"), 
        name: "sourceManage",
        meta: {
          title: '素材管理',
          icon: 'el-icon-decument',
          roles: ["sys:sourceManage"],
        },
      },
      {
        path: "/adManage", 
        component: () => import("@/views/operate/advertisement.vue"), 
        name: "adManage",
        meta: {
          title: '广告管理',
          icon: 'el-icon-decument',
          roles: ["sys:adManage"],
        },
      },
      {
        path: "/notice", 
        component: () => import("@/views/operate/notice.vue"), 
        name: "notice",
        meta: {
          title: '公告管理',
          icon: 'el-icon-decument',
          roles: ["sys:notice"],
        },
      },
      {
        path: "/agreement", 
        component: () => import("@/views/operate/agreement.vue"), 
        name: "agreement",
        meta: {
          title: '协议管理',
          icon: 'el-icon-decument',
          roles: ["sys:agreement"], 
        },
      },
      {
        path: "/news", 
        component: () => import("@/views/operate/news.vue"), 
        name: "news",
        meta: {
          title: '消息管理',
          icon: 'el-icon-decument',
          roles: ["sys:news"], 
        },
      },
      {
        path: "/noticeCat",
        component: () => import("@/views/notice/noticeCatList.vue"),
        name: "noticeCat",
        meta: {
          title: '消息分类',
          icon: 'el-icon-decument',
          roles: ["sys:theme"],
        },
      },
      {
        path: "/noticeContent",
        component: () => import("@/views/notice/noticeContentList.vue"),
        name: "noticeContent",
        meta: {
          title: '消息内容',
          icon: 'el-icon-decument',
          roles: ["sys:theme"],
        },
      },
      {
        path: "/newNoticeContent",
        component: () => import("@/views/notice/components/newNoticeContent.vue"),
        name: "newNoticeContent",
        meta: {
          title: '消息内容-消息新增',
          icon: 'el-icon-decument',
          roles: ["sys:freight"],
        },
      },
      {
        path: "/crawlJob",
        component: () => import("@/views/crawl/crawlJobList.vue"),
        name: "crawlJob",
        meta: {
          title: '爬虫任务',
          icon: 'el-icon-decument',
          roles: ["sys:freight"],
        },
      },
    ]
  },{
    path: "/marketManagement",
    component: Layout,
    name: "marketManagement",
    meta: {
      title: '营销管理',
      icon: 'el-icon-decument',
      roles: ["sys:marketManagement"],
    },
    children: [
      {
        path: "/flashSale",
        component: () => import("@/views/marketManagement/flashSale.vue"),
        name: "flashSale",
        meta: {
          title: '限时抢购',
          icon: 'el-icon-decument',
          roles: ["sys:flashSale"],
        },
      },{
        path: "/collage",
        component: () => import("@/views/marketManagement/collage.vue"),
        name: "collage",
        meta: {
          title: '拼团',
          icon: 'el-icon-decument',
          roles: ["sys:collage"],
        },
      },{
        path: "/coupon",
        component: () => import("@/views/marketManagement/coupon.vue"),
        name: "coupon",
        meta: {
          title: '优惠券',
          icon: 'el-icon-decument',
          roles: ["sys:coupon"],
        },
      },{
        path: "/markupPurchase",
        component: () => import("@/views/marketManagement/markupPurchase.vue"),
        name: "lottery",
        meta: {
          title: '加价购',
          icon: 'el-icon-decument',
          roles: ["sys:coupon"],
        },
      },
    ]
  }, {
    path: "/supplier",
    component: Layout,
    name: "supplier",
    meta: {
      title: '供应商管理',
      icon: 'el-icon-decument',
      roles: ["sys:supplier"],
    },
    children: [
      {
        path: "/supplierList", 
        component: () => import("@/views/supplier/supplierList.vue"), 
        name: "supplierList",
        meta: {
          title: '供应商列表',
          icon: 'el-icon-decument',
          roles: ["sys:supplierList"], 
        },
      },
      {
        path: "/returnAddress",  
        component: () => import("@/views/supplier/returnAddress.vue"), 
        name: "returnAddress",
        meta: {
          title: '退货地址',
          icon: 'el-icon-decument',
          roles: ["sys:returnAddress"], 
        },
      },
      {
        path: "/supplierDetail",
        component: () => import("@/views/supplier/supplierDetail.vue"),
        name: "supplierDetail",
      }
    ]
  }, {
    path: "/order",
    component: Layout,
    name: "order",
    meta: {
      title: '订单管理',
      icon: 'el-icon-decument',
      roles: ["sys:order"],
    },
    children: [
      {
        path: "/orderList", 
        component: () => import("@/views/orders/ordersList.vue"), 
        name: "orderList",
        meta: {
          title: '订单列表',
          icon: 'el-icon-decument',
          roles: ["sys:orderList"], 
        },
      },
      {
        path: "/vipOrderList", 
        component: () => import("@/views/operate/vipOrderList.vue"), 
        name: "vipOrderList",
        meta: {
          title: '权益订单',
          icon: 'el-icon-decument',
          roles: ["sys:vipOrderList"], 
        },
      },
      {
        path: "/afterSalesList", 
        component: () => import("@/views/orders/afterSalesList.vue"), 
        name: "afterSalesList",
        meta: {
          title: '售后管理',
          icon: 'el-icon-decument',
          roles: ["sys:afterSalesList"], 
        },
      },
      {
        path: "/invoiceList", 
        component: () => import("@/views/invoice/invoiceList.vue"), 
        name: "invoiceList",
        meta: {
          title: '发货单列表',
          icon: 'el-icon-decument',
          roles: ["sys:invoiceList"],  
        },
      }
    ]
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  }, {
    path: '/:catchAll(.*)',
    name: '/404',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
//挂载路由导航守卫
//to是我们跳转的路径，from是来自的路径，next为放行函数
router.beforeEach((to: any, from: any, next: any) => {
  //如果用户访问登录页，直接放行
  if (to.path === "/login") return next();
  //从sessionStorage中获取到保存的token值
  const tokenStr = window.localStorage.getItem("seckillToken");
  //没有token，强制跳转到登录页面
  if (!tokenStr) return next("/login");
  next();//有token，直接放行
});


export default router
